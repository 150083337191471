import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using OBS Studio with your WQHD Camera",
  "path": "/Frequently_Asked_Question/WQHD_OBS/",
  "dateChanged": "2022-11-30",
  "author": "Mike Polinowski",
  "excerpt": "Despite, as I think, correct inputs, I get no contact to the cam. Is there perhaps a back door to establish contact from my Linux PC?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Using OBS Studio with your WQHD Camera' dateChanged='2022-11-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='Despite, as I think, correct inputs, I get no contact to the cam. Is there perhaps a back door to establish contact from my Linux PC?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_OBS/' locationFR='/fr/Frequently_Asked_Question/WQHD_OBS/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "using-obs-studio-with-your-instar-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-obs-studio-with-your-instar-wqhd-camera",
        "aria-label": "using obs studio with your instar wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using OBS Studio with your INSTAR WQHD Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Despite, as I think, correct inputs, I get no contact to the cam. Is there perhaps a "back door" to establish contact from my Linux PC?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: On Linux you can install `}<a parentName="p" {...{
        "href": "https://obsproject.com/download#linux"
      }}>{`OBS Studio`}</a>{` from `}<a parentName="p" {...{
        "href": "https://flathub.org/apps/details/com.obsproject.Studio"
      }}>{`Flathub`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`flatpak `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` flathub com.obsproject.Studio
flatpak run com.obsproject.Studio`}</code></pre></div>
    <p><em parentName="p">{`Make sure you have `}<a parentName="em" {...{
          "href": "https://flatpak.org/setup/"
        }}>{`Flathub installed`}</a></em></p>
    <h2 {...{
      "id": "adding-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-camera",
        "aria-label": "adding your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Camera`}</h2>
    <p>{`Start with creating a `}<strong parentName="p">{`Scene`}</strong>{` by clicking on `}<strong parentName="p">{`+`}</strong>{` icon in the bottom left:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb1f72635ca66f0501322d3900be0022/84ee5/INSTAR_WQHD_OBS_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB/ElEQVQoz32T6Y7aMBSFMwFEnIUkhATwbsdbNui0UDTT7Uff/51GDpqp1KIeHVmWre/ca+k6oExgQhGmiFBEGCYMYcpESyjHlL2v7AjR/gA/fDhCiHBQFFVZVmW5y7J86bXyXixDr8Vsv5m1DMNFHKdluSuKKs+3Hp75XZJkwV96Cv5VFIE7XBTVf+FHAiB+DIdhCOJ4HYHIC2RZlqRpBEAEvOIkmSs/gGsAkiTdPF9+jOeX6dNrf7r1p5sbrrr7YvqLHa5uvC6Wy/UalGX9AI7j/Pz5tx2+KXfrT7/s+L2ffuruhauL0FehLuFiFUXRHzjPt3m+LYoKgGS1WjcNrhtU1ZBwI1Wv7YSp2tWo3uN6j5+ewmiufKeCpjnsD7BpjtttvdnkcZJmWZ6kWZyk/pVJmqZZmm3SdONvNpuyrKpqv9t5B7YblemENEIarZ2QmvGWidbY3nWjkJoLxYVW2rlu1KZrldHGzYdt4KbX1t2k/SrtlTLpR4pwhOkwjafns7KmVcpoa2zXDyfXjUq7YTz3w8nYPjhCBCGGiBwhxoTNwZZxiQgnvKVctdrIVvmr1gihCBWuG8fprE0XUCYoE4T6akIq1w3W+t7w+5zfcyEimDCICGXSuqHvJ6VdwLjkPo8fjohQLqS6tz1/GL4/QIgIm9M/rI1T2mLC3wDOaU0Q1bjBlQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb1f72635ca66f0501322d3900be0022/e4706/INSTAR_WQHD_OBS_01.avif 230w", "/en/static/fb1f72635ca66f0501322d3900be0022/d1af7/INSTAR_WQHD_OBS_01.avif 460w", "/en/static/fb1f72635ca66f0501322d3900be0022/7f308/INSTAR_WQHD_OBS_01.avif 920w", "/en/static/fb1f72635ca66f0501322d3900be0022/85f86/INSTAR_WQHD_OBS_01.avif 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb1f72635ca66f0501322d3900be0022/a0b58/INSTAR_WQHD_OBS_01.webp 230w", "/en/static/fb1f72635ca66f0501322d3900be0022/bc10c/INSTAR_WQHD_OBS_01.webp 460w", "/en/static/fb1f72635ca66f0501322d3900be0022/966d8/INSTAR_WQHD_OBS_01.webp 920w", "/en/static/fb1f72635ca66f0501322d3900be0022/ceabe/INSTAR_WQHD_OBS_01.webp 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb1f72635ca66f0501322d3900be0022/81c8e/INSTAR_WQHD_OBS_01.png 230w", "/en/static/fb1f72635ca66f0501322d3900be0022/08a84/INSTAR_WQHD_OBS_01.png 460w", "/en/static/fb1f72635ca66f0501322d3900be0022/c0255/INSTAR_WQHD_OBS_01.png 920w", "/en/static/fb1f72635ca66f0501322d3900be0022/84ee5/INSTAR_WQHD_OBS_01.png 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb1f72635ca66f0501322d3900be0022/c0255/INSTAR_WQHD_OBS_01.png",
              "alt": "Using OBS Studio with your INSTAR WQHD Camera",
              "title": "Using OBS Studio with your INSTAR WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the `}<strong parentName="p">{`Sources`}</strong>{` panel (next to `}<strong parentName="p">{`Scenes`}</strong>{`) click on `}<strong parentName="p">{`+`}</strong>{` to a `}<strong parentName="p">{`Media Source`}</strong>{` for your INSTAR camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/38a65/INSTAR_WQHD_OBS_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACBklEQVQoz42RfW+bMBCHeUmaZISCA4Fg+/yGbcBASJs0ardJ+2ff/zNNJO2mSZvUnx5Zlk7P3Vn2gAlCABMgFDChhLIKU8YloYxQuJ0zRXF4pzwUZVWU1aHCXooyhHKE8jhOwnCxWCzDcBGGoe8H/pzgThCEQRj6QbDZRAjlSZolyW6W03T2oyj2bvF9PwznPg8PD8vlcnGL7/v36mq1QWifprP1R95u3+XHBA3TpXHTOL30x7Npx8ZN682Xe3W93iCU/1eOH7PT5ef49EOZF6aeeP0s9GW1jj4lb2M0TN/d+Fbb5zu6Oa/Wn52MxtPX/vg6TK/D9HZn/U85SXdJskvTLIq2v+VheuuGazdcbXdu3MV259XqLzlJZsu7/RgpSpzlRZKgKIr3Be7Ha+vOtn3ub2PH07cU7eM4iR9TtMuyvMj3Zb4vPWut1lpIKaQ0tpVKU5AUFONaqkYbp+rO2EEq07S9tV2trbGtkFrI2uvGq7Inro9CHxlXwCQFfqhw67r+OPJaCSmVVNa2/TB1btCm6YfJudE2zgPRgnQgHeUtBf7RuCYggCsQtdJW1YaCkMoIUQMTbTcMx5OxnQeMM8YBGCZUyLrtetvMu1HgAJxQhglUmGICFDgmlHFlG9e5UZvWY1wKWVMmDhUBJuY7CEwAmKDAywPGBBif3wJM3NGm1aYBJn4BTIBQ4IYv05wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/e4706/INSTAR_WQHD_OBS_02.avif 230w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/d1af7/INSTAR_WQHD_OBS_02.avif 460w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/7f308/INSTAR_WQHD_OBS_02.avif 920w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/ba661/INSTAR_WQHD_OBS_02.avif 1075w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/a0b58/INSTAR_WQHD_OBS_02.webp 230w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/bc10c/INSTAR_WQHD_OBS_02.webp 460w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/966d8/INSTAR_WQHD_OBS_02.webp 920w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/4690a/INSTAR_WQHD_OBS_02.webp 1075w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/81c8e/INSTAR_WQHD_OBS_02.png 230w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/08a84/INSTAR_WQHD_OBS_02.png 460w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/c0255/INSTAR_WQHD_OBS_02.png 920w", "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/38a65/INSTAR_WQHD_OBS_02.png 1075w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6450c7e0ff7cd44c9931d125a61b6fd0/c0255/INSTAR_WQHD_OBS_02.png",
              "alt": "Using OBS Studio with your INSTAR WQHD Camera",
              "title": "Using OBS Studio with your INSTAR WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to add the URL under which your camera's RTSP stream can be reached. `}<strong parentName="p">{`Note`}</strong>{` that there are differences between the `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`WQHD 2k+`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9008_HD/Video_Streaming/"
      }}>{`HD and Full HD`}</a>{` models. For a WQHD camera use the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f9d649905835fe394d58f745c56cc4b/18539/INSTAR_WQHD_OBS_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACWElEQVQoz23SSW/TQBgGYPdANyjQSqixnXiLZ/F4PPt4t5O0hISq4ogqhBASQuLIjf/Ar0ZJKpVDR8/xe78ZvRpH6oaKkgrLuKE7lhTK1r0uO7VXtWO3uDV1b+qeqZIwRZjOuZG2cbwp8qbIDzL33ezk+PTF8cnF66swobMwOwhiEsZ5lNBoTsMkD+InjuvDiQe8KX5z6Tr7c3J67vpo4oGJB/fAI3fPe7ILuz70pvjyyjuET89e+jPsTeFh7/8Ow/t55PrwmfDZ2aswJtMAISQINSnkSVqkkM8BC2MSRFkQZdMAJSl7PoywxERBYgvRYiIB4hALgESU5OGuAkJ5U4j2UBj0Z9nllXd0tAufn18kgAHIpV0tbj+1w2Zn3PTjturWulzpctUOW2kWzrWbXrvpxIdv304eCzs5B0gyUVPZm3ZjqxuhR2lGZZcH0iyqdq3swgFYp0gBbGYRiWIURMCfzeeAQSRSrHFRZ0SnkMNMxnN6eHMQZZjo3bNvbj603VhVXVn32/uH2+3nQnSzEE0DlFOjTW/sWFaLvCijJD+0FUR4FqAwJs5w91uMv/LmJ+t+NNs/1f1fqB5wJggtlVl0w7Zq13W3NtXK1jdlc1u17+tu3Q6b3c2k/IrLb1n5HZsviFic2zlg2o7ctDEgc0RTlIM0T1KapDRKCMykqXeLdLl0uCy53H/sQjPZS7ugvEkhw6zJVA95y80gZZMClqRFPKcoU9KMtl4J0zu6XOpqxVWPMi3MUHdraQYmWqFHJjuY6byolB2F6rnquOqEHoblXb/8KMz4DxLYiNT1/d6oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f9d649905835fe394d58f745c56cc4b/e4706/INSTAR_WQHD_OBS_03.avif 230w", "/en/static/0f9d649905835fe394d58f745c56cc4b/d1af7/INSTAR_WQHD_OBS_03.avif 460w", "/en/static/0f9d649905835fe394d58f745c56cc4b/7f308/INSTAR_WQHD_OBS_03.avif 920w", "/en/static/0f9d649905835fe394d58f745c56cc4b/f59ef/INSTAR_WQHD_OBS_03.avif 1074w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f9d649905835fe394d58f745c56cc4b/a0b58/INSTAR_WQHD_OBS_03.webp 230w", "/en/static/0f9d649905835fe394d58f745c56cc4b/bc10c/INSTAR_WQHD_OBS_03.webp 460w", "/en/static/0f9d649905835fe394d58f745c56cc4b/966d8/INSTAR_WQHD_OBS_03.webp 920w", "/en/static/0f9d649905835fe394d58f745c56cc4b/2b317/INSTAR_WQHD_OBS_03.webp 1074w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f9d649905835fe394d58f745c56cc4b/81c8e/INSTAR_WQHD_OBS_03.png 230w", "/en/static/0f9d649905835fe394d58f745c56cc4b/08a84/INSTAR_WQHD_OBS_03.png 460w", "/en/static/0f9d649905835fe394d58f745c56cc4b/c0255/INSTAR_WQHD_OBS_03.png 920w", "/en/static/0f9d649905835fe394d58f745c56cc4b/18539/INSTAR_WQHD_OBS_03.png 1074w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f9d649905835fe394d58f745c56cc4b/c0255/INSTAR_WQHD_OBS_03.png",
              "alt": "Using OBS Studio with your INSTAR WQHD Camera",
              "title": "Using OBS Studio with your INSTAR WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now be seeing your camera's video stream after clicking on `}<strong parentName="p">{`Ok`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d3069e0b95cd0e6d10712055d2ea254/84ee5/INSTAR_WQHD_OBS_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADY0lEQVQ4y1WRS28bZRSGvSqEpA4hTpyxc5mkvow9tmc8M/bcfb+mSYkTKCUNkSCU0oSUUiltERWowuIiQEStaIQUVeImaDewggpY8A9Y8XseZGcBXTyb83163vfoBPSih6o7KLqDNkCzUFUTRSlgWSX0wSxvYWg2+byFptlkMwYZWScr68iyRlLWkDI6slIkIEQSCNE4wqzEXCjCxPgo0zMhpsOTBIMjjI09RfD0CKfHniY4PsrY6ClCoeeYX1hgbjbKoiAyLcSZEWKEhRgBIToQJobDVCzN7hubPLh7h19/vE8ss0CvV+O3h19x+OlNfvnukAUpylqvwfHdD7myu41uWISmzxCZPfEMhZFogsnwGWy1wL3+dY4/v8XvPxySkOfZutDi78cP+Pm4z9EXtxmZDnLxfIuf7vc52HuFluczMbX0pDA6m2Q8JLJerfP4m8949HWff/78Hlld4uKFDn88OuKj969x6+bbiCmRbEHm0v5lNjbXcQoWk4OG0f83nE3w7NQiXb/Mt1/e5uiTA/56eA8pK2K6KgfXr9DbOMu1vdcQpXmkvMTWzjZWs0wskSYsxBGeFCaHK7uayY2rrw754N23CEYnyBpp1l5aw657VFbaPBMNMSWGWdIznMmniMWShIXEf8LwTGx4oYmQiJk3eXN3h/39HT7uv4ewFEaxFC5d3cNfXSbXXeZUdIqRyCQzts2kkiEiDi4cZyZyQiCRMohLOjFJJydp1LwqZadEt94hl8qQScqUTR9bMzE0i/hSkpgYJ53WkOIyycU0c6KMuJQZEqjWWjhuCdNyMYoO9c55/PoGdvl57NI5HL+D47dQdB8l76EXq1huC7/cxfHaWF4Lt9xBN6tDAl73HZTSLmn7Mln3dezODYrLfQqdOxiNAyrdc7R6qxSrFexyhbJXp1Tu0uxuUGuu4ZQ6tLov0Giv41dXCKQKm0iFLaTiNpLxMsmUjpTWSMkGUlpH95uYjRX0Shen2qFW7aBqHkWngV6skNM8qs01OmdfxC0vE8ipBRS1QE7RkbMaubyLapSGHzOKg+G1KVZWUO0Wpt/G9ZpkFBs5ZyPJBVS9RKm2Sr3dOxFqhcpJUt4jkdKH6abTQNV95JxFTrWRsyaxhDJsPAjM5Z0TVAdFc3FLHRyvNXz7F8k52gSGG86QAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d3069e0b95cd0e6d10712055d2ea254/e4706/INSTAR_WQHD_OBS_04.avif 230w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/d1af7/INSTAR_WQHD_OBS_04.avif 460w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/7f308/INSTAR_WQHD_OBS_04.avif 920w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/85f86/INSTAR_WQHD_OBS_04.avif 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d3069e0b95cd0e6d10712055d2ea254/a0b58/INSTAR_WQHD_OBS_04.webp 230w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/bc10c/INSTAR_WQHD_OBS_04.webp 460w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/966d8/INSTAR_WQHD_OBS_04.webp 920w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/ceabe/INSTAR_WQHD_OBS_04.webp 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d3069e0b95cd0e6d10712055d2ea254/81c8e/INSTAR_WQHD_OBS_04.png 230w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/08a84/INSTAR_WQHD_OBS_04.png 460w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/c0255/INSTAR_WQHD_OBS_04.png 920w", "/en/static/1d3069e0b95cd0e6d10712055d2ea254/84ee5/INSTAR_WQHD_OBS_04.png 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d3069e0b95cd0e6d10712055d2ea254/c0255/INSTAR_WQHD_OBS_04.png",
              "alt": "Using OBS Studio with your INSTAR WQHD Camera",
              "title": "Using OBS Studio with your INSTAR WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now continue following our previous tutorial to setup the OBS Youtube, Twitch, etc streaming:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Youtube_Videostreaming_from_your_Camera/"
        }}>{`Youtube Video Streaming from your Camera`}</a></li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      